import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import TextField from '../TextField';
import { Validation } from '../Validation';

const useStyles = makeStyles((theme) => ({
  textFieldSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0)
  },
  textFieldTitle: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  textFieldPlacement: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2)
  }
}));

const FormField = forwardRef(
  (
    {
      title,
      placeholder,
      deleteButton,
      onEndAdornmentPress,
      style,
      textFieldStyle,
      isValid,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <div className={clsx(classes.textFieldSection, style)}>
        {title && (
          <div className={classes.textFieldTitle}>
            <Validation isValid={isValid} />
            <Typography variant="subtitle2">{title}</Typography>
          </div>
        )}
        <div className={classes.textFieldPlacement}>
          <TextField
            ref={ref}
            placeholder={placeholder}
            deleteButton={deleteButton}
            onEndAdornmentPress={onEndAdornmentPress}
            textFieldStyle={textFieldStyle}
            {...rest}
          />
        </div>
      </div>
    );
  }
);

FormField.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  sectionStyle: PropTypes.string,
  textFieldStyle: PropTypes.string,
  deleteButton: PropTypes.bool,
  onClickRemoveAlternative: PropTypes.func,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  simpleCheck: PropTypes.bool,
  id: PropTypes.string,
  data: PropTypes.array
};

export default FormField;
