import { Box, Container, makeStyles } from '@material-ui/core';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ActionModal from '../../components/ActionModal/ActionModal';
import AddUserModal from '../../components/AddUserModal/AddUserModal';
import BackButton from '../../components/BackButton';
import DragAndDropList from '../../components/DragAndDropList/DragAndDropList';
import MealModal from '../../components/MealModal/MealModal';
import NutritionModal from '../../components/NutritionModal/NutritionModal';
import Page from '../../components/Page';
import {
  addMeal,
  addUserNutritionPlan,
  deleteMeal,
  deleteNutritionPlan,
  setSelectedPlan,
  updateMeal
} from '../../features/nutrition-plans/slice';
import { useNutritionPlanMeals } from '../../firestore';
import useNutritionById from '../../firestore/useNutritionById';
import useToggle from '../../hooks/useToggle';
import Header from '../eventdetail/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.lightBlue,
    minHeight: '100%',
    paddingTop: theme.spacing(3)
  }
}));

const NutritionDetailView = () => {
  const classes = useStyles();
  const [, setOpen] = useState(false);
  const { id } = useParams();
  const { nutritionPlan } = useNutritionById(id);
  const { meals } = useNutritionPlanMeals(id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mealToDelete, setMealToDelete] = useState(null);
  const [isDeleting, toggleIsDeleting] = useToggle(false);
  const [isAddingUser, toggleAddUser] = useToggle(false);
  const { t } = useTranslation();
  const [isEditing, toggleIsEditing] = useToggle(false);
  const [isAddingMeal, toggleAddingMeal] = useToggle(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { isLoading } = useSelector((state) => state.nutrition);
  console.log({ isLoading });

  useEffect(() => {
    if (selectedItem) {
      handleClickOpen();
    }
  }, [selectedItem]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (selectedItem) {
      setSelectedItem(null);
    } else {
      toggleAddingMeal();
    }
  };

  const handleListItemClick = ({ id }) => {
    if (id) {
      setSelectedItem(meals.find((item) => item.id === id));
    } else {
      toggleAddingMeal();
    }
  };

  const handleDeleteWorkout = (workoutId) => {
    setMealToDelete(workoutId);
  };

  const shouldDeleteMeal = async () => {
    const payload = {
      planId: id,
      mealId: mealToDelete,
      meals: nutritionPlan?.meals.filter((item) => item.id !== mealToDelete)
    };
    try {
      await dispatch(deleteMeal(payload)).unwrap();
    } catch (error) {
      console.log({ error });
    } finally {
      onMealDeleted();
    }
  };

  const onMealDeleted = () => setMealToDelete(null);

  const handleEditEvent = async () => {
    try {
      await dispatch(setSelectedPlan(nutritionPlan)).unwrap();
    } catch (error) {
      console.log({ error });
    } finally {
      toggleIsEditing();
    }
  };

  const handleDeletePlan = async () => {
    try {
      await dispatch(deleteNutritionPlan({ id: nutritionPlan?.id })).unwrap();

      navigate(-1);
    } catch (error) {
      console.log({ error });
    } finally {
      toggleIsDeleting();
    }
  };

  const handleAddUser = () => {
    toggleAddUser();
  };

  const shouldAddUser = async ({ user }) => {
    try {
      const nutritionPlanWithMeals = {
        ...nutritionPlan,
        meals
      };

      await dispatch(
        addUserNutritionPlan({ user, nutritionPlan: nutritionPlanWithMeals })
      ).unwrap();
    } catch (error) {
      console.log({ error });
    } finally {
      toggleAddUser();
    }
  };

  const shouldEditMeal = async (dataToEdit) => {
    const normalizedData = dataToEdit.map(({ ...rest }) => ({
      ...rest
    }));
    try {
      await dispatch(updateMeal({ id, meals: normalizedData })).unwrap();
    } catch (error) {
      console.log({ error });
    } finally {
      handleClose();
    }
  };

  const shouldAddMeal = async (dataToAdd) => {
    const normalizedData = dataToAdd.map(({ ...rest }) => ({
      ...rest
    }));
    try {
      await dispatch(
        addMeal({ id, index: meals.length, meals: normalizedData })
      ).unwrap();
    } catch (error) {
      console.log({ error });
    } finally {
      handleClose();
    }
  };

  return (
    <Page className={classes.root} title="Customers">
      <Container>
        <BackButton onClick={() => navigate(-1)} />
        <Suspense fallback="loading">
          <Header
            data={nutritionPlan}
            onEditEvent={handleEditEvent}
            onDelete={toggleIsDeleting}
            counterText={t('mealsCount', {
              count: nutritionPlan?.meals?.length ?? 0
            })}
          />
          <Box mt={2}>
            <DragAndDropList
              onAddUser={handleAddUser}
              onDelete={handleDeleteWorkout}
              data={meals}
              primaryButtonTitle={t('addMeal')}
              onListItemClick={handleListItemClick}
            />
          </Box>
        </Suspense>
        {isAddingMeal && (
          <MealModal
            isOpen
            onClose={handleClose}
            onSave={shouldAddMeal}
            isLoading={isLoading}
          />
        )}
        {selectedItem && (
          <MealModal
            isOpen
            onClose={handleClose}
            defaultValues={[
              {
                id: selectedItem.id,
                title: selectedItem.title,
                description: selectedItem.description,
                isValid: true
              },
              ...(selectedItem?.ingredients
                ? selectedItem.ingredients.map((item) => ({
                    ...item,
                    isValid: true
                  }))
                : [])
            ]}
            onSave={shouldEditMeal}
            isLoading={isLoading}
          />
        )}
      </Container>
      <ActionModal
        open={mealToDelete}
        onSecondaryAction={onMealDeleted}
        onPrimaryAction={shouldDeleteMeal}
        title={t('deleteMeal')}
        description={t('deleteMealDesc')}
        primaryButtonText={t('delete')}
        emoji="🗑"
        isLoading={isLoading}
      />
      <ActionModal
        open={isDeleting}
        onSecondaryAction={() => toggleIsDeleting()}
        onPrimaryAction={handleDeletePlan}
        title={t('deleteNutritionPlan')}
        description={t('deleteNutritionDesc')}
        primaryButtonText={t('delete')}
        emoji="🗑"
        isLoading={isLoading}
      />
      {isEditing && (
        <NutritionModal
          isOpen={true}
          handleClose={toggleIsEditing}
          defaultValues={nutritionPlan}
          isLoading={isLoading}
        />
      )}
      {isAddingUser && (
        <AddUserModal
          isOpen={true}
          handleClose={toggleAddUser}
          onAddUser={shouldAddUser}
          isLoading={isLoading}
        />
      )}
    </Page>
  );
};

export default NutritionDetailView;
