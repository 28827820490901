import { useEffect, useState } from 'react';
import app from '../firebase';

const useNutritionById = (id) => {
  const [data, setData] = useState({
    error: null,
    loading: true,
    nutritionPlan: {}
  });

  useEffect(() => {
    const unsubscribe = () => {
      try {
        app
          .firestore()
          .collection('nutrition_plans')
          .doc(id)
          .onSnapshot(async (snapshot) => {
            const nutritionPlan = snapshot.data();
            setData({
              loading: false,
              error: null,
              nutritionPlan: { id: snapshot.id, ...nutritionPlan }
            });
          });
      } catch (error) {
        setData({
          error,
          loading: false,
          nutritionPlan: {}
        });
      }
    };

    unsubscribe();
  }, []);

  return data;
};

export default useNutritionById;
