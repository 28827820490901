import PropTypes from 'prop-types';
import React from 'react';
import ModalTitle from './ModalTitle';

const ModalHeader = ({ title, introText }) => {
  return <ModalTitle title={title} introText={introText} />;
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  introText: PropTypes.string,
  maybeButtonTitle: PropTypes.string
};

export default ModalHeader;
