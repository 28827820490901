import { Avatar, Box, Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BaseDialog from '../BaseDialog/BaseDialog';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import TextFieldSection from '../TextFieldSection';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: 12
    }
  },
  dialog: {
    background: theme.palette.background.lightBlue,
    padding: theme.spacing(9, 7, 7, 7)
  },
  iconBackground: {
    width: '140px',
    height: '140px',
    borderRadius: '50%',
    backgroundColor: '#EFF3F9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  waveIcon: {
    fontSize: '60px'
  },
  modalTitle: {
    color: theme.palette.secondary.main,
    maxWidth: 430
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    fontWeight: 600,
    lineHeight: '32px'
  },
  text: {
    marginTop: '8px',
    fontSize: '14px',
    color: '#5B5971'
  },
  buttons: {
    display: 'flex',
    minWidth: 332,
    justifyContent: 'space-between',
    marginTop: theme.spacing(4)
  },
  primaryButton: {
    borderRadius: 12,
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 12,
    padding: theme.spacing(2),
    width: 216,
    height: 48
  },
  secondaryButton: {
    borderRadius: 12,
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 12,
    padding: '18px',
    width: 216,
    height: 48
  },
  avatar: {
    width: theme.spacing(18),
    height: theme.spacing(18)
  }
}));

const OnboardingDialog = ({
  onPrimaryAction,
  onSecondaryAction,
  primaryButtonText,
  secondaryButtonText,
  emoji,
  avatar,
  defaultValues,
  open
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    trigger,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || {
      firstName: '',
      lastName: ''
    }
  });

  useEffect(() => {
    trigger();
  }, [errors]);

  return (
    <BaseDialog open={open}>
      <form onSubmit={handleSubmit(onSecondaryAction)}>
        <Box className={classes.iconContainer}>
          {avatar ? (
            <Avatar src={avatar} className={classes.avatar} />
          ) : (
            <Box className={classes.iconBackground}>
              <Typography className={classes.waveIcon}>
                {emoji ?? '👋'}
              </Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.textContainer}>
          <Typography className={classes.title}>
            {t('onboarding.title')}
          </Typography>
          <Typography variant="body2" className={classes.text}>
            {t('onboarding.desc')}
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <TextFieldSection
              title={t('name.firstName')}
              placeholder={t('name.firstNamePlaceholder')}
              isValid={getValues('firstName')}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('error.required')
                }
              }}
              name="firstName"
              defaultValue={defaultValues?.firstName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldSection
              title={t('name.lastName')}
              placeholder={t('name.lastNamePlaceholder')}
              isValid={getValues('lastName')}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('error.required')
                }
              }}
              name="lastName"
              defaultValue={defaultValues?.lastName}
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <PrimaryButton onClick={onSecondaryAction} type="submit">
            {secondaryButtonText ?? t('remove')}
          </PrimaryButton>
          {onPrimaryAction && (
            <>
              <Box mx={1} />
              <PrimaryButton secondary onClick={onPrimaryAction}>
                {primaryButtonText ?? t('logout')}
              </PrimaryButton>
            </>
          )}
        </div>
      </form>
    </BaseDialog>
  );
};

OnboardingDialog.propTypes = {
  onPrimaryAction: PropTypes.func,
  open: PropTypes.bool,
  avatar: PropTypes.string
};

OnboardingDialog.defaultProps = {
  // avatar: 'https://randomuser.me/api/portraits/men/15.jpg'
};

export default OnboardingDialog;
