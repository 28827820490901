import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { arrayOf, object } from 'prop-types';
import React from 'react';
import { PlusCircle as AddIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // width: 175,
    // height: 175,
    backgroundColor: theme.palette.background.lightBlue,
    boxShadow: 'none'
  },
  addButton: {
    width: 'auto',
    backgroundColor: theme.palette.common.white,
    borderRadius: '9999px'
  },
  avatar: {
    width: 175,
    height: 175,
    borderRadius: '9999px',
    marginBottom: theme.spacing(2),
    position: 'relative'
  },
  nameOverlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 175,
    width: 175,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.2) 100%)',
    borderRadius: '9999px'
  },
  nameText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '0 16px'
  },
  gridContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start'
    }
  }
}));

const CoachList = ({ onAddClass, data, title, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item>
          <Card className={classes.root}>
            <CardActionArea onClick={onAddClass} className={classes.addButton}>
              <Box
                height={175}
                width={175}
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
              >
                <AddIcon color="#7672ff" />
                <Box height={16} />
                <Typography color="secondary" variant="h4">
                  {title ?? t('create event')}
                </Typography>
              </Box>
            </CardActionArea>
          </Card>
        </Grid>
        {children ??
          data.map((item) => (
            <Grid item key={item.id}>
              <CardActionArea className={classes.avatar}>
                <Box>
                  <Avatar src={item.media} className={classes.avatar} />
                  <Box className={classes.nameOverlay} />
                </Box>
              </CardActionArea>
              <Box>
                <Typography variant="h3" align="center" color="inherit">
                  {item.name}
                </Typography>
              </Box>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

CoachList.propTypes = {
  data: arrayOf(object)
};

export default CoachList;
