import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { db } from '../../firebase';

export const updateUserNutritionPlan = createAsyncThunk(
  'userNutritionPlan/update',
  async ({ user, ...payload }) => {
    await db
      .collection('users')
      .doc(user.id)
      .collection('nutrition_plans')
      .doc(payload.id)
      .set(payload);
    return { user, ...payload };
  }
);

export const getUserNutritionPlan = createAsyncThunk(
  'userNutritionPlan/get',
  async ({ nutritionPlanId, id, ...rest }) => {
    let currentNutritionPlan = null;

    const querySnapshot = await db
      .collection('users')
      .doc(id)
      .collection('nutrition_plans')
      .doc(nutritionPlanId)
      .get();

    if (querySnapshot.exists) {
      currentNutritionPlan = querySnapshot.data();
    }
    if (currentNutritionPlan) {
      return {
        ...currentNutritionPlan,
        user: { id, ...rest }
      };
    }
    return null;
  }
);

export const slice = createSlice({
  name: 'userNutritionPlan',
  initialState: {
    data: null,
    isLoading: null,
    error: null
  },
  reducers: {
    setNutritionPlan: (state, { payload }) => {
      state.data = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserNutritionPlan.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserNutritionPlan.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getUserNutritionPlan.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateUserNutritionPlan.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUserNutritionPlan.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(updateUserNutritionPlan.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

const { actions, reducer } = slice;

export const { setNutritionPlan } = actions;

export default reducer;
