import { Box, Grid, makeStyles } from '@material-ui/core/';
import MUIModal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import ModalHeader from './ModalHeader';
import ModalNavBar from './ModalNavBar';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    // padding: theme.spacing(0, 5, 3, 5)
  },
  contentContainer: {
    padding: theme.spacing(0, 5)
  },
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.lightBlue,
    overflow: 'auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  }
}));

const Modal = ({
  isOpen,
  handleClose,
  title,
  introText,
  handleSave,
  children,
  navBarRightButtonTitle,
  isLoading,
  disabled
}) => {
  const classes = useStyles();

  return (
    <MUIModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box className={classes.wrapper}>
        <Grid container justifyContent="center">
          <Grid item xs={11}>
            <ModalNavBar
              onSave={handleSave}
              handleClose={handleClose}
              navBarRightButtonTitle={navBarRightButtonTitle}
              isLoading={isLoading}
              isButtonDisabled={disabled}
            />
            <Box my={2} />
          </Grid>
          <Grid item xs={7}>
            <ModalHeader
              title={title}
              introText={introText}
              handleClose={handleClose}
              onSave={handleSave}
            />
            <Box my={3} />
          </Grid>
          <Grid item xs={7}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </MUIModal>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  introText: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.object,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  onSave: PropTypes.func,
  maybeButtonTitle: PropTypes.string,
  navBarRightButtonTitle: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Modal;
