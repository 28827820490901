import { useEffect, useState } from 'react';
import app from '../firebase';

const useAllUsers = () => {
  const [data, setData] = useState({
    error: null,
    loading: true,
    users: []
  });

  useEffect(() => {
    const unsubscribe = app
      .firestore()
      .collection('users')
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((item) => ({
          key: item.id,
          id: item.id,
          ...item.data()
        }));
        setData({
          loading: false,
          error: null,
          users: data
        });
      });

    return () => unsubscribe();
  }, []);

  return data;
};

export default useAllUsers;
