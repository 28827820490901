import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { nanoid } from 'nanoid';
import { db } from '../../firebase';

export const addUserNutritionPlan = createAsyncThunk(
  'nutrition/addUserNutritionPlan',
  async ({ nutritionPlan, user }) => {
    const { key } = user;
    const userRef = db.collection('users').doc(key);
    const batch = db.batch();

    batch.set(
      userRef,
      {
        nutritionPlanId: nutritionPlan.id
      },
      {
        merge: true
      }
    );

    batch.set(
      userRef.collection('nutrition_plans').doc(nutritionPlan.id),
      nutritionPlan,
      {
        merge: true
      }
    );

    return batch.commit().then(() => {
      return nutritionPlan;
    });
  }
);

export const deleteNutritionPlan = createAsyncThunk(
  'nutrition/deleteNutritionPlan',
  ({ id }) => {
    if (!id) {
      return;
    }

    const batch = db.batch();

    const planRef = db.collection('nutrition_plans').doc(id);

    batch.delete(planRef);

    return batch.commit();
  }
);

export const deleteMeal = createAsyncThunk(
  'nutrition/deleteMeal',
  async (payload) => {
    const batch = db.batch();

    const planRef = db.collection('nutrition_plans').doc(payload.planId);

    batch.update(planRef, {
      meals: firebase.firestore.FieldValue.arrayRemove(payload.mealId)
    });

    const mealRef = db
      .collection('nutrition_plans')
      .doc(payload.planId)
      .collection('meals')
      .doc(payload?.mealId);

    batch.delete(mealRef);

    return batch.commit();
  }
);

export const setNutritionPlan = createAsyncThunk(
  'nutrition/setNutritionPlan',
  async (payload) => {
    const { title, description, media, id } = payload;
    return db
      .collection('nutrition_plans')
      .doc(id)
      .set({ title, description, media }, { merge: true });
  }
);
export const addMeal = createAsyncThunk(
  'nutrition/addMeal',
  async (payload) => {
    const batch = db.batch();
    const { id, index, meals: data } = payload;
    const meal = data.shift();

    const ingredients = data.map((item) => ({ ...item, id: nanoid() }));

    const planRef = db.collection('nutrition_plans').doc(id);
    const mealsCollectionRef = planRef.collection('meals');

    const docId = mealsCollectionRef.doc().id;
    const mealRef = mealsCollectionRef.doc(docId);

    if (meal) {
      batch.update(planRef, {
        meals: firebase.firestore.FieldValue.arrayUnion(docId)
      });
    }

    batch.set(mealRef, { ...meal, index, ingredients });

    return batch.commit();
  }
);

export const updateMeal = createAsyncThunk(
  'nutrition/updateMeal',
  async (payload) => {
    const batch = db.batch();
    const { id, meals: data } = payload;
    const meal = data.shift();
    const ingredients = data;

    const planRef = db.collection('nutrition_plans').doc(id);
    const mealsCollectionRef = planRef.collection('meals');

    const mealRef = mealsCollectionRef.doc(meal.id);

    batch.set(
      mealRef,
      { title: meal.title, description: meal.description, ingredients },
      { merge: true }
    );

    // TODO: update meals array only if a meal is added or removed
    if (meal) {
      batch.set(
        planRef,
        {
          updatedAt: firebase.firestore.Timestamp.now()
        },
        {
          merge: true
        }
      );
    }

    return batch.commit().then(() => {
      return payload;
    });
  }
);

export const slice = createSlice({
  name: 'nutrition',
  initialState: {
    selectedPlan: null,
    isSaved: false,
    isLoading: false
  },
  reducers: {
    addNewNutritionPlan: (state) => {
      state.selectedPlan = {
        id: nanoid(),
        title: '',
        meals: [],
        isValid: false,
        imageUrl: ''
      };
    },
    updateNutritionPlan: (state, { payload: { title, description } }) => {
      state.selectedPlan.title = title;
      state.selectedPlan.description = description;
    },
    setSelectedPlan: (state, { payload }) => {
      state.selectedPlan = payload;
    },
    toggleLoading: (state) => {
      state.isLoading = !state.isLoading;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addUserNutritionPlan.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addUserNutritionPlan.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(addUserNutritionPlan.rejected, () => {});

    builder.addCase(deleteNutritionPlan.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteNutritionPlan.fulfilled, (state) => {
      state.isLoading = false;
      state.selectedPlan = null;
    });

    builder.addCase(deleteNutritionPlan.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(setNutritionPlan.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(setNutritionPlan.fulfilled, (state) => {
      state.isLoading = false;
      // state.selectedPlan = null;
    });

    builder.addCase(setNutritionPlan.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addMeal.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addMeal.fulfilled, (state) => {
      state.isLoading = false;
      state.selectedPlan = null;
    });

    builder.addCase(addMeal.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteMeal.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteMeal.fulfilled, (state) => {
      state.isLoading = false;
      state.selectedPlan = null;
    });

    builder.addCase(deleteMeal.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMeal.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateMeal.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateMeal.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const selectSelectedNutritionPlan = (state) =>
  state.nutrition.selectedPlan;

const { actions, reducer } = slice;
export const {
  addNewNutritionPlan,
  updateNutritionPlan,
  setSelectedPlan,
  toggleLoading
} = actions;

export default reducer;
