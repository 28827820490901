import { CircularProgress, Grid, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import i18next from 'i18next';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { Navigate } from 'react-router';
import { AuthContext } from '../../Authentication';
import ActionModal from '../../components/ActionModal/ActionModal';
import InputVerificationCode from '../../components/InputVerificationCode/InputVerificationCode';
import { useLoading } from '../../hooks/useLoading';
import useFirebaseSMSAuth from './useFirebaseSMSAuth';

const Header = () => {
  return (
    <Box component="section" mb={8}>
      <Typography variant="h1" color="textPrimary" align="left">
        Gymply 💪
      </Typography>
    </Box>
  );
};

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {'Gymply '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flex: 0.5,
    flexDirection: 'column'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(8)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 28,
    minHeight: 56
  },
  root: {
    display: 'flex',
    flex: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  },
  left: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  right: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    backgroundImage: `url(/gymply-bg.webp)`
  }
}));

const PhoneAuth = () => {
  const classes = useStyles();

  const { isLoading, withLoading } = useLoading();
  const [verificationId, setVerificationId] = useState(null);
  const [phone, setPhone] = useState('');
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const {
    clearError: clearAuthError,
    error: authErr,
    signInWithVerificationCode,
    sendVerificationCode
  } = useFirebaseSMSAuth();

  const { currentUser, isCheckingAuth } = useContext(AuthContext);

  const handleSignIn = async (phone) => {
    try {
      const verificationId = await sendVerificationCode(phone);
      setVerificationId(verificationId);
    } catch (error) {
      console.log(error);
    }
  };

  const clearError = () => {
    clearAuthError();
    setError('');
  };

  // const setEn = () => {
  //   i18next.changeLanguage('en');
  // };

  // const setEt = () => {
  //   i18next.changeLanguage('et');
  // };

  const onChange = (value) => {
    setPhone('+' + value);
  };

  const handleCodeChange = async (code) => {
    if (code.length === 6) {
      try {
        await withLoading(() =>
          signInWithVerificationCode(code, verificationId)
        );
      } catch (error) {
        setError(error);
      }
    }
  };

  const handleSubmit = async () => {
    if (!verificationId && phone) {
      try {
        await withLoading(() => handleSignIn(phone));
      } catch (error) {
        setError(error);
      }
    }
  };

  if (currentUser) {
    return <Navigate to="/app/workouts" />;
  }

  if (isCheckingAuth) {
    return (
      <div className={classes.root}>
        <CircularProgress size={24} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div id="recaptcha-container"></div>
      {/* <Box top={16} left={window.innerWidth / 2 - 116} position="absolute">
        <IconButton
          onClick={setEt}
          color={i18next.language === 'et' ? 'primary' : 'default'}
        >
          <ReactCountryFlag countryCode="EE" />
        </IconButton>
        <IconButton
          color={i18next.language === 'en' ? 'primary' : 'default'}
          onClick={setEn}
        >
          <ReactCountryFlag countryCode="GB" />
        </IconButton>
      </Box> */}
      <div className={classes.left}>
        <div className={classes.paper}>
          <Box mt={2} bottom={8} left={0} right={0} position="absolute">
            <Copyright />
          </Box>
          <Header />
          {!verificationId ? (
            <Typography component="h4" variant="h4" color="textSecondary">
              {t('enterPhoneNr')}
            </Typography>
          ) : (
            <Typography component="h4" variant="h4" color="textSecondary">
              {t('enterCode')}
            </Typography>
          )}
          <Box mt={2} />
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ marginLeft: 0 }}>
              <Box flexDirection="row" display="flex">
                {!verificationId && (
                  <Box position="relative">
                    <PhoneInput
                      isValid={() => {
                        // return true if the value is valid for the selected country)}
                        return true;
                      }}
                      onChange={onChange} // passed function receives the phone value
                      specialLabel=""
                      inputProps={{
                        autoFocus: true
                      }}
                      inputStyle={{
                        border: '1px solid #EFF3F9',
                        borderRadius: 8,
                        height: 50,
                        fontSize: 16
                      }}
                      countryCodeEditable={false}
                      country="se"
                    />
                    <Box
                      position="absolute"
                      top={0}
                      right={0}
                      bottom={0}
                      display="flex"
                    >
                      {!verificationId && !isLoading && (
                        <IconButton
                          color={
                            i18next.language === 'en' ? 'primary' : 'default'
                          }
                          onClick={handleSubmit}
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                )}
                {verificationId && (
                  <InputVerificationCode onChange={handleCodeChange} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flex={1}
                mt={4}
                visibility={isCheckingAuth || isLoading ? 'visible' : 'hidden'}
              >
                <CircularProgress size={24} />
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <ActionModal
        open={!!error || !!authErr}
        emoji="❌"
        title="Error"
        description={t(`${error}`)}
        secondaryButtonText={t('ok')}
        onSecondaryAction={clearError}
      />
    </div>
  );
};

export default PhoneAuth;
