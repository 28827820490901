import { Box, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { array, func, object } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import SectionList from './SectionList';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontWeight: '700'
  },
  sectionButton: {
    borderRadius: 0,
    borderBottomRightRadius: 4
  },
  container: {
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4
  }
}));

const Sections = ({
  onAddSectionClick,
  onSectionItemClick,
  sections,
  onDelete,
  selectedIndex,
  setSections,
  title,
  addButtonTitle
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      // width={320}
      display="flex"
      flexDirection="column"
      bgcolor={theme.palette.primary.surface}
      className={classes.container}
      mr={5}
    >
      <Box flexGrow={1} px={5} py={3}>
        <Box mb={1}>
          <Typography variant="h4">{title ?? t('exercises')}</Typography>
        </Box>
        {/* <Typography style={{ color: '#85839B' }}>
          <Typography
            color="primary"
            display="inline"
            className={classes.tooltip}
          >
            {t('create_lesson.tooltip')}
          </Typography>
          {t('create_lesson.tooltip_description')}
        </Typography> */}
      </Box>
      <Box maxHeight={425} overflow="auto">
        <SectionList
          sections={sections}
          onDelete={onDelete}
          setSections={setSections}
          onSectionItemClick={onSectionItemClick}
          selectedIndex={selectedIndex}
          // selectedSectionIndex={sections?.findIndex(
          //   (item) => selectedSection?.index === item.index
          // )}
        />
      </Box>

      <Button onClick={onAddSectionClick} buttonStyle={classes.sectionButton}>
        {addButtonTitle ?? `${t('add exercise')}`}
      </Button>
    </Box>
  );
};

Sections.propTypes = {
  onAddSectionClick: func,
  sections: array,
  onDelete: func,
  setSelectedSection: func,
  setSections: func,
  selectedSection: object
};

export default Sections;
