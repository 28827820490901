import { Box } from '@material-ui/core';
import { func, string } from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ url, onVideoPlayerStateChanged }) => {
  return (
    <Box ml={3} mt={2} height={360}>
      <ReactPlayer
        style={{ overflow: 'hidden', borderRadius: 8 }}
        controls={true}
        width="100%"
        height="100%"
        // url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
        // url="https://www.youtube.com/watch?v=UKwgBviSotU"
        url={url}
        onReady={(player) => onVideoPlayerStateChanged(null, player)}
        onError={(error) => onVideoPlayerStateChanged(error, null)}
      />
    </Box>
  );
};

VideoPlayer.propTypes = {
  videoId: string,
  setVideoDuration: func
};

export default VideoPlayer;
