import {
  Box,
  ListItemIcon,
  ListItemText,
  ListItem as MUIListItem,
  Typography
} from '@material-ui/core';
import MUIList from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import { default as Add } from '@material-ui/icons/Add';
import { default as PersonAdd } from '@material-ui/icons/PersonAdd';
import React, { forwardRef, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reorderWorkouts } from '../../features/workoutplans/slice';
import Lesson from './Lesson';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4)
  },
  deleteIcon: theme.palette.text.hint,
  listContainer: {
    '&:hover': {
      visibility: 'visible',
      '& $listItemSecondaryAction': {
        visibility: 'visible'
      }
    }
  },
  listItemSecondaryAction: {
    visibility: 'hidden',
    right: 0
  },
  listItem: {
    minHeight: 50,
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    marginBottom: 8
  },
  listItemIcon: {
    minWidth: 32,
    color: theme.palette.secondary.main,
    marginTop: 1
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0
  },
  lessonList: {}
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggableListItem = ({
  item,
  index,
  selectedIndex,
  onDelete,
  onAddSection,
  onEdit,
  onClick,
  ...props
}) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <Lesson
          key={item.id}
          item={item}
          selectedIndex={selectedIndex}
          onDelete={onDelete}
          onEdit={onEdit}
          onClick={onClick}
          onAddSection={onAddSection}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          {...props}
        />
      )}
    </Draggable>
  );
};

const List = React.memo(
  ({
    listItems,
    selectedIndex,
    onDelete,
    onEdit,
    onAddSection,
    onAddUser,
    primaryButtonTitle,
    secondaryButtonTitle,
    onListItemClick,
    canAddWorkouts
  }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <MUIList classes={{ padding: classes.list }}>
        <Box flexDirection="row" display="flex">
          {canAddWorkouts && (
            <MUIListItem
              button
              classes={{
                container: classes.listContainer,
                root: classes.listItem
              }}
              onClick={onListItemClick}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Add />
              </ListItemIcon>
              <ListItemText>
                <Typography color="secondary" variant="h4">
                  {primaryButtonTitle ? primaryButtonTitle : t('add workout')}
                </Typography>
              </ListItemText>
            </MUIListItem>
          )}
          {onAddUser && (
            <>
              <Box width={16} />
              <MUIListItem
                button
                classes={{
                  container: classes.listContainer,
                  root: classes.listItem
                }}
                onClick={onAddUser}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <PersonAdd />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="secondary" variant="h4">
                    {secondaryButtonTitle
                      ? secondaryButtonTitle
                      : t('add user')}
                  </Typography>
                </ListItemText>
              </MUIListItem>
            </>
          )}
        </Box>
        {listItems?.map((item, index) => (
          <DraggableListItem
            key={item.id}
            item={item}
            index={index}
            selectedIndex={selectedIndex}
            onDelete={onDelete}
            onEdit={onEdit}
            onAddSection={onAddSection}
            onAddUser={onAddUser}
            // onClick={onListItemClick}
            onClick={onListItemClick}
          />
        ))}
      </MUIList>
    );
  }
);

const DragAndDropList = forwardRef(
  ({
    data,
    onDelete,
    onEdit,
    onAddSection,
    onAddUser,
    primaryButtonTitle,
    secondaryButtonTitle,
    onListItemClick,
    canAddWorkouts = true
  }) => {
    const classes = useStyles();
    const [listItems, setListItems] = useState(data);
    const [selectedIndex] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
      setListItems(data);
    }, [data]);

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }
      if (result.destination.index === result.source.index) {
        return;
      }

      const updatedLessons = reorder(
        listItems,
        result.source.index,
        result.destination.index
      );

      const reorderedWorkouts = updatedLessons.map((item, index) => ({
        id: item.id,
        newIndex: index
      }));

      dispatch(
        reorderWorkouts({
          workouts: reorderedWorkouts
        })
      );
      setListItems(updatedLessons);
    };

    const handleAddWorkout = () => {
      console.log('add workout');
    };

    return (
      <div className={classes.lessonList}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <List
                  listItems={listItems}
                  selectedIndex={selectedIndex}
                  onListItemClick={onListItemClick}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onAddSection={onAddSection}
                  onAddWorkout={handleAddWorkout}
                  onAddUser={onAddUser}
                  primaryButtonTitle={primaryButtonTitle}
                  secondaryButtonTitle={secondaryButtonTitle}
                  canAddWorkouts={canAddWorkouts}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
);

export default DragAndDropList;
