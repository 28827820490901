import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import Autocomplete from './Autocomplete/Autocomplete';
import TextField from './TextField';
import { Validation } from './Validation';

const useStyles = makeStyles((theme) => ({
  textFieldSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0)
  },
  textFieldTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  textFieldPlacement: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2)
  }
}));

const TextFieldSection = ({
  title,
  placeholder,
  deleteButton,
  onEndAdornmentPress,
  sectionStyle,
  textFieldStyle,
  isValid,
  autocomplete,
  textField = true,
  phoneInput,
  autocompleteData,
  rules,
  ...rest
}) => {
  console.log({ rules });
  const classes = useStyles();
  const [isFieldValid, setFieldValid] = useState(false);
  const {
    field: { onChange, onBlur, ref }
  } = useController({
    name: rest.name,
    control: rest.control,
    rules,
    defaultValue: rest.defaultValue
  });

  useEffect(() => {
    setFieldValid(isValid);
  }, [isValid]);

  return (
    <div className={clsx(classes.textFieldSection, sectionStyle)}>
      {title && (
        <div className={classes.textFieldTitle}>
          <Validation isValid={isFieldValid} />
          <Typography variant="subtitle2">{title}</Typography>
        </div>
      )}
      <div className={classes.textFieldPlacement}>
        {autocomplete && (
          <Autocomplete
            placeholder={placeholder}
            data={autocompleteData}
            {...rest}
          />
        )}
        {textField && !autocomplete && (
          <TextField
            ref={ref}
            placeholder={placeholder}
            deleteButton={deleteButton}
            onEndAdornmentPress={onEndAdornmentPress}
            textFieldStyle={textFieldStyle}
            {...rest}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
        {phoneInput && (
          <PhoneInput
            isValid={() => {
              // return true if the value is valid for the selected country)}
              return true;
            }}
            onChange={onChange} // passed function receives the phone value
            specialLabel=""
            inputStyle={{
              border: '1px solid #EFF3F9',
              borderRadius: 8,
              height: 50,
              fontSize: 16,
              width: '100%'
            }}
            value={rest?.defaultValue}
            countryCodeEditable={false}
            country="se"
          />
        )}
      </div>
    </div>
  );
};

TextFieldSection.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  sectionStyle: PropTypes.string,
  textFieldStyle: PropTypes.string,
  deleteButton: PropTypes.bool,
  onClickRemoveAlternative: PropTypes.func,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  simpleCheck: PropTypes.bool,
  id: PropTypes.string,
  data: PropTypes.array
};

export default TextFieldSection;
