import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormField from './FormField';
import { questions } from './questions';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%'
  },
  button: {
    borderRadius: theme.spacing(2) / 1.5,
    border: '2px solid black',
    width: 112,
    height: 48,
    fontWeight: 600,
    '&:hover': {
      borderWidth: 2
    },
    '&:disabled': {
      color: theme.palette.action.disabled
    }
  },
  textStyle: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.primary.main
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'block'
  }
}));

const CoachForm = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const { handleSubmit, register, formState } = useFormContext();
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);

  const classes = useStyles();
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  const handleNextQuestion = () => {
    if (isLastQuestion) {
      onSubmit();
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {questions.map((item, index) => (
          <FormField
            key={item.id}
            title={item.question}
            name={item.id}
            style={
              currentQuestionIndex === index ? classes.visible : classes.hidden
            }
            placeholder={item.placeholder}
            isValid={
              !formState.errors[item.id] && formState.dirtyFields[item.id]
            }
            multiline={item.multiline}
            rows={item.multiline ? 4 : undefined}
            {...register(item.id, { required: true })}
          />
        ))}
      </form>
      <Box justifyContent="flex-end" display="flex" marginTop={8}>
        <Button
          variant="outlined"
          color="primary"
          className={clsx(classes.button)}
          onClick={handleNextQuestion}
          type="submit"
          disabled={
            formState.errors[questions[currentQuestionIndex].id] ||
            !formState.dirtyFields[questions[currentQuestionIndex].id]
          }
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : isLastQuestion ? (
            t('Finish')
          ) : (
            t('Next')
          )}
        </Button>
      </Box>
    </div>
  );
};

export default CoachForm;
