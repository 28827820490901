const emojis = {
  0: '0️⃣',
  1: '1️⃣',
  2: '2️⃣',
  3: '3️⃣',
  4: '4️⃣',
  5: '5️⃣',
  6: '6️⃣',
  7: '7️⃣',
  8: '8️⃣',
  9: '9️⃣'
};
export const numberToEmoji = (value) =>
  `${value}`
    .split('')
    .map((item) => emojis[item])
    .join('');

export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((item, index) => ({ ...item, index }));
};

export const createThumbnailUrl = (url) => {
  if (url) {
    const youtubeId = url.split('v=')[1];

    return `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`;
  }
  return FALLBACK_URLS.WORKOUT;
};

export const FALLBACK_URLS = {
  WORKOUT:
    'https://res.cloudinary.com/alvar-aronija/image/upload/v1737896276/gymply/workout-plan.webp',
  NUTRITON:
    'https://res.cloudinary.com/alvar-aronija/image/upload/v1737896277/gymply/nutrition-plan.webp',
  USER: 'https://res.cloudinary.com/alvar-aronija/image/upload/v1701513749/gymply/avatar_p3qcbb_d9m2f8.png',
  COACH:
    'https://res.cloudinary.com/alvar-aronija/image/upload/v1737896276/gymply/ai-coach.webp'
};
