export const questions = [
  {
    id: 'name',
    question: 'Name',
    multiline: false,
    placeholder: ''
  },
  {
    id: 'question2',
    question: 'How many years of experience do you have as a trainer?',
    required: true,
    multiline: true,
    placeholder: ''
  },
  {
    id: 'question3',
    question: 'How many days a week do you recommend clients train?',
    required: true,
    multiline: true,
    placeholder: 'List any relevant certifications, degrees, or qualifications'
  },
  {
    id: 'question4',
    question: 'How many grams of protein do you recommend clients eat per day?',
    required: true,
    multiline: true,
    placeholder: ''
  },
  {
    id: 'question5',
    question: 'How many grams of fat do you recommend clients eat per day?',
    required: true,
    multiline: true,
    placeholder: ''
  },
  {
    id: 'question6',
    question: 'How many grams of carbs do you recommend clients eat per day?',
    required: true,
    multiline: true,
    placeholder: ''
  }
];
