import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../Authentication';
import { db } from '../../firebase';
import { FALLBACK_URLS } from '../../utils';
import Modal from '../Modal';
import CoachForm from './CoachForm';
import { questions } from './questions';

const AddCoachModal = (props) => {
  const [isLoading, setLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      description: ''
    }
  });

  const { t } = useTranslation();

  const uploadData = async (obj) => {
    if (props.defaultValues) {
      shouldUpdateData(obj);
    } else {
      console.log({ values: methods.getValues() });

      const dataToSave = questions.map((item) => ({
        question: item.question,
        answer: methods.getValues(item.id)
      }));

      console.log({ dataToSave });

      const { name, description } = methods.getValues();
      try {
        await db
          .collection('users')
          .doc(currentUser.uid)
          .collection('coaches')
          .add({
            name,
            description,
            media: FALLBACK_URLS.COACH,
            createdBy: {
              id: currentUser.uid,
              name: currentUser.displayName ?? ''
            },
            rating: 0,
            prompt: dataToSave
          });

        // navigate(`/app/coaches/${ref.id}`);
      } catch (error) {
        console.log({ error });
      }
    }
  };

  const shouldUploadData = (data) => uploadData(data);

  const shouldUpdateData = () => {
    const { name, description } = methods.getValues();
    return db
      .collection('coaches')
      .doc(props.defaultValues.id)
      .set({ name, description }, { merge: true });
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      if (props.defaultValues) {
        await shouldUpdateData();
      } else {
        await shouldUploadData();
      }
    } catch (error) {
      console.log({ error });
    } finally {
      props.handleClose();
    }
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={props.isOpen}
        handleClose={props.handleClose}
        // handleSave={handleSave}
        title={t('create coach', { defaultValue: 'Create Coach' })}
        isLoading={isLoading}
        disabled={!methods.formState.isValid}
        introText={t('create coach intro text')}
      >
        <CoachForm onSubmit={handleSave} isLoading={isLoading} />
      </Modal>
    </FormProvider>
  );
};

export default AddCoachModal;
