import {
  Box,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  ListItem as MUIListItem,
  Typography
} from '@material-ui/core';
import MUIList from '@material-ui/core/List';
import { default as Delete } from '@material-ui/icons/Delete';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  array,
  bool,
  elementType,
  func,
  node,
  number,
  object,
  string
} from 'prop-types';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  reorderSections,
  setSelectedExercise
} from '../features/workoutplans/slice';
import { numberToEmoji } from '../utils';

const useStyles = makeStyles((theme) => ({
  deleteIcon: theme.palette.text.hint,
  listContainer: {
    '&:hover': {
      visibility: 'visible',
      '& $listItemSecondaryAction': {
        visibility: 'visible'
      }
    }
  },
  listItemSecondaryAction: {
    visibility: 'hidden',
    right: 0
  },
  listItem: {
    minHeight: 50
  },
  listItemIcon: {
    minWidth: 32,
    color: theme.palette.secondary.main,
    marginTop: 1
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const ListItem = React.forwardRef(
  (
    {
      children,
      onListItemClick,
      selectedIndex,
      itemIndex,
      onDelete,
      id,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
      <div {...props} ref={ref}>
        <MUIListItem
          button
          classes={{
            container: classes.listContainer,
            root: classes.listItem
          }}
          onClick={(event) => onListItemClick({ event, itemIndex, id })}
          selected={selectedIndex === itemIndex}
        >
          {itemIndex !== 0 ? (
            <Typography variant="h6">{`${numberToEmoji(
              itemIndex
            )}`}</Typography>
          ) : (
            <Box mx={1} />
          )}
          <Box mx={1} />
          <ListItemText>
            <Typography variant="subtitle2">{children}</Typography>
          </ListItemText>
          {itemIndex !== 0 && (
            <ListItemSecondaryAction
              className={classes.listItemSecondaryAction}
            >
              <IconButton onClick={() => onDelete(id, itemIndex)}>
                <Delete style={{ color: theme.palette.text.hint }} />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </MUIListItem>
      </div>
    );
  }
);

const Section = ({ item, index, ...props }) => {
  const { t } = useTranslation();
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <ListItem
          valid={false}
          itemIndex={index}
          ref={provided.innerRef}
          id={item.id}
          {...props}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {index === 0 ? t('info') : item?.title}
        </ListItem>
      )}
    </Draggable>
  );
};

//eslint-disable-next-line
const List = React.memo(({ sections, ...props }) => {
  const classes = useStyles();
  return (
    <MUIList classes={{ padding: classes.list }}>
      {sections?.map((item, index) => (
        <Section item={item} index={index} key={item.id} {...props} />
      ))}
    </MUIList>
  );
});

const SectionList = ({
  sections,
  onDelete,
  selectedIndex,
  onSectionItemClick
}) => {
  // const [state, setState] = useState({ sections });
  const dispatch = useDispatch();

  const handleListItemClick = ({ itemIndex, id }) => {
    typeof onSectionItemClick === 'function'
      ? onSectionItemClick({ id, index: itemIndex })
      : dispatch(setSelectedExercise({ id }));
  };

  const onDragEnd = (result) => {
    // setSelectedIndex(result.destination.index);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    dispatch(
      reorderSections({
        startIndex: result.source.index,
        endIndex: result.destination.index
      })
    );

    // const reorderedSections = reorder(
    //   sections,
    //   result.source.index,
    //   result.destination.index
    // );

    // console.log(JSON.stringify({ reorderedSections }, null, 2));
    // TODO: update section index
    // const reorderedSections = sections.map((section, index) => ({
    //   ...section,
    //   index
    // }));

    // setSections(reorderedSections);

    // setState({ sections });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <List
              sections={sections}
              selectedIndex={selectedIndex}
              // selectedIndex={state.sections.findIndex()}

              onListItemClick={handleListItemClick}
              onDelete={onDelete}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

Section.propTypes = {
  item: object,
  index: number
};

List.propTypes = {
  sections: array,
  selectedIndex: number,
  onListItemClick: func
};

ListItem.propTypes = {
  valid: bool,
  children: node,
  onListItemClick: func,
  onDelete: func,
  selectedIndex: number,
  itemIndex: number,
  icon: elementType,
  id: string
};

SectionList.propTypes = {
  data: array,
  onDelete: func,
  setSelectedSection: func,
  selectedSection: object,
  selectedSectionIndex: number,
  setSections: func
};

export default SectionList;
