import { Box, Container, makeStyles } from '@material-ui/core';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ActionModal from '../../components/ActionModal/ActionModal';
import AddUserModal from '../../components/AddUserModal/AddUserModal';
import AddWorkoutModal from '../../components/AddWorkoutModal/AddWorkoutModal';
import BackButton from '../../components/BackButton';
import DragAndDropList from '../../components/DragAndDropList/DragAndDropList';
import Page from '../../components/Page';
import PlanModal from '../../components/PlanModal/PlanModal';
import {
  addNewWorkout,
  addUserWorkout,
  deletePlan,
  deleteWorkoutById,
  setSelectedWorkout
} from '../../features/workoutplans/slice';
import useEventById from '../../firestore/useEventById';
import useToggle from '../../hooks/useToggle';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.lightBlue,
    minHeight: '100%',
    paddingTop: theme.spacing(3)
  }
}));

const EventDetailView = () => {
  const classes = useStyles();
  const [, setOpen] = useState(false);
  const [selectedItem] = useState(null);
  const { id } = useParams();
  const { event } = useEventById(id);
  const plan = useSelector((state) => state.plans.selectedPlan);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [workoutToDelete, setWorkoutToDelete] = useState(null);
  const [isDeleting, toggleIsDeleting] = useToggle(false);
  const [isAddingUser, toggleAddUser] = useToggle(false);
  const { t } = useTranslation();
  const [isEditing, toggleIsEditing] = useToggle(false);

  useEffect(() => {
    if (selectedItem) {
      handleClickOpen();
    }
  }, [selectedItem]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(setSelectedWorkout(null));
  };

  const handleListItemClick = ({ id: workoutId }) => {
    if (workoutId) {
      dispatch(setSelectedWorkout(workoutId));
    } else {
      dispatch(addNewWorkout());
    }
  };

  const handleDeleteWorkout = (workoutId) => {
    setWorkoutToDelete(workoutId);
  };

  const shouldDeleteWorkout = () => {
    dispatch(deleteWorkoutById({ planId: id, workoutId: workoutToDelete }));
    onWorkoutDeleted();
  };

  const onWorkoutDeleted = () => setWorkoutToDelete(null);

  const handleEditEvent = () => {
    toggleIsEditing();
  };

  const handleDeletePlan = async () => {
    try {
      await dispatch(deletePlan()).unWrap();
      navigate('..');
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddUser = () => {
    toggleAddUser();
  };

  const shouldAddUser = async ({ user }) => {
    try {
      await dispatch(addUserWorkout({ plan, user })).unWrap();
    } catch (error) {
      console.log(error);
    } finally {
      toggleAddUser();
    }
  };

  return (
    <Page className={classes.root} title="Customers">
      <Container>
        <BackButton />
        <Suspense fallback="loading">
          <Header
            data={event}
            onEditEvent={handleEditEvent}
            onDelete={toggleIsDeleting}
          />
          <Box mt={2}>
            {plan?.workouts && (
              <DragAndDropList
                onAddUser={handleAddUser}
                onDelete={handleDeleteWorkout}
                data={plan?.workouts}
                onListItemClick={handleListItemClick}
              />
            )}
          </Box>
        </Suspense>
        {plan?.selectedWorkout && (
          <AddWorkoutModal
            isOpen={Boolean(plan?.selectedWorkout)}
            onClose={handleClose}
          />
        )}
      </Container>
      <ActionModal
        open={workoutToDelete}
        onSecondaryAction={onWorkoutDeleted}
        onPrimaryAction={shouldDeleteWorkout}
        title={t('deleteWorkout')}
        description={t('deleteWorkoutDesc')}
        primaryButtonText={t('delete')}
        emoji="🗑"
      />
      <ActionModal
        open={isDeleting}
        onSecondaryAction={() => toggleIsDeleting()}
        onPrimaryAction={handleDeletePlan}
        title={t('deletePlan')}
        description={t('deletePlanDesc')}
        primaryButtonText={t('delete')}
        emoji="🗑"
      />
      {isEditing && (
        <PlanModal
          isOpen={true}
          handleClose={toggleIsEditing}
          modalTitle={t('edit plan')}
          defaultValues={event}
        />
      )}
      {isAddingUser && (
        <AddUserModal
          isOpen={true}
          handleClose={toggleAddUser}
          defaultValues={event}
          onAddUser={shouldAddUser}
        />
      )}
    </Page>
  );
};

export default EventDetailView;
