import { IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { default as DeleteIcon } from '@material-ui/icons/DeleteOutline';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const useStyles = makeStyles((theme) => ({
  textfield: {
    borderRadius: 8,
    background: 'white',
    border: `1px solid ${theme.palette.primary.surface}`,
    padding: '0.5rem',
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 400,
    '& input::placeholder': {
      color: theme.palette.text.hint
    }
  }
}));

const TextField = forwardRef(
  (
    { placeholder, textFieldStyle, defaultValue, onEndAdornmentPress, ...rest },
    ref
  ) => {
    const classes = useStyles();

    const handleClearField = () => {
      onEndAdornmentPress();
    };

    return (
      <InputBase
        {...rest}
        inputRef={ref}
        className={clsx(classes.textfield, textFieldStyle)}
        placeholder={placeholder}
        defaultValue={defaultValue}
        endAdornment={
          onEndAdornmentPress ? (
            <InputAdornment position="end">
              <IconButton
                style={{ display: 'flex', justifyContent: 'center' }}
                onClick={handleClearField}
              >
                <DeleteIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
        fullWidth
      />
    );
  }
);

TextField.propTypes = {
  placeholder: PropTypes.string,
  textFieldStyle: PropTypes.string,
  deleteButton: PropTypes.bool,
  onClickRemoveAlternative: PropTypes.func,
  onChange: PropTypes.func
};

export default TextField;
