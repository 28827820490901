import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../Authentication';
import app from '../firebase';

const useCoaches = () => {
  const { currentUser } = useContext(AuthContext);
  const [data, setData] = useState({
    error: null,
    loading: true,
    data: []
  });

  useEffect(() => {
    const unsubscribe = app
      .firestore()
      .collection('users')
      .doc(currentUser.uid)
      .collection('coaches')
      .onSnapshot((snapshot) => {
        var docs = [];
        snapshot.forEach((doc) => {
          docs.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setData({
          error: null,
          loading: false,
          data: docs
        });
      });

    return () => unsubscribe();
  }, [currentUser]);

  return data;
};

export default useCoaches;
