import { useEffect, useState } from 'react';
import { db } from '../firebase';

const useIngredients = () => {
  const [data, setData] = useState({
    error: null,
    loading: true,
    ingredients: []
  });

  useEffect(() => {
    const unsubscribe = db.collection('ingredients').onSnapshot((snapshot) => {
      const ingredients = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      setData({
        loading: false,
        error: null,
        ingredients
      });
    });

    return () => unsubscribe();
  }, []);

  return data;
};

export default useIngredients;
