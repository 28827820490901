import { Box, CircularProgress } from '@material-ui/core/';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ActionModal from '../ActionModal/ActionModal';
import Modal from '../Modal';
import UsersList from '../UsersList';

const AddUserModal = ({ isOpen, handleClose, handleSave, onAddUser }) => {
  const [user, setUser] = useState(null);
  const [selectedFile] = useState(null);
  const { selectedPlan, isLoading } = useSelector((state) => state.plans);

  const { t } = useTranslation();

  const onSave = () => {
    handleSave({ file: selectedFile });
  };

  const handleListItemClick = ({ user }) => {
    setUser(user);
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSave={onSave}
      title={t('add user')}
      isLoading={isLoading}
      disabled={!selectedPlan?.isValid}
    >
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex={1}
        >
          <CircularProgress />
        </Box>
      ) : (
        <UsersList onListItemClick={handleListItemClick} />
      )}
      {!!user && (
        <ActionModal
          open={!!user}
          title={t('add', { name: user?.name?.first })}
          description=""
          avatar={user?.picture?.large}
          onSecondaryAction={() => setUser(null)}
          onPrimaryAction={() => onAddUser({ user })}
          primaryButtonText={t('confirm')}
        />
      )}
    </Modal>
  );
};

AddUserModal.propTypes = {
  introText: PropTypes.string,
  isOpen: PropTypes.bool,
  isEditing: PropTypes.bool,
  children: PropTypes.instanceOf(Element),
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  onAddUser: PropTypes.func,
  onSave: PropTypes.func,
  addUser: PropTypes.func,
  state: PropTypes.object
};

export default AddUserModal;
