import firebase from 'firebase';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AuthContext } from '../../Authentication';
import {
  setNutritionPlan,
  toggleLoading,
  updateNutritionPlan
} from '../../features/nutrition-plans/slice';
import { db, storage } from '../../firebase';
import { FALLBACK_URLS } from '../../utils';
import NutritionPlanModal from '../AddEventModal/AddEventModal';

const NutritionModal = (props) => {
  const { currentUser } = useContext(AuthContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { title, description, media } = useSelector(
    (state) => state.nutrition.selectedPlan
  );
  const { isLoading } = useSelector((state) => state.nutrition);
  const navigate = useNavigate();

  const handleFieldChange = (value) => {
    dispatch(
      updateNutritionPlan({
        title: value.title,
        description: value.description
      })
    );
  };

  const uploadData = useCallback(async (obj) => {
    if (props.defaultValues) {
      shouldUpdateData(obj);
    } else {
      try {
        const ref = await db
          .collection('nutrition_plans')
          .add({ ...obj, createdAt: firebase.firestore.Timestamp.now() });

        navigate(`/app/nutrition/${ref.id}`);
      } catch (error) {
        console.error('Error uploading data:', error);
      }
    }
  }, []);

  const shouldUploadData = (data) => uploadData(data);

  const shouldUpdateData = () =>
    dispatch(
      setNutritionPlan({
        title,
        description,
        media: media ?? FALLBACK_URLS.NUTRITON,
        id: props?.defaultValues?.id
      })
    ).unwrap();

  const uploadFile = (data) => {
    const name = data.file.file.path;
    const file = data.file.file;
    const storageRef = storage.ref().child(`images/nutritionplans/${name}`);

    storageRef.put(file).on(
      'state_changed',
      (snap) => {
        Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
      },
      (err) => {
        console.error(err);
      },
      async () => {
        // get the public download img url
        const downloadUrl = await storageRef.getDownloadURL();
        if (props.defaultValues?.id) {
          await updateNutritionPlan({
            title,
            description,
            media: downloadUrl,
            id: props.defaultValues.id
          }).unwrap();
          toggleLoading();
          props.handleClose();
          return;
        }
        await shouldUploadData({
          title,
          description,
          media: downloadUrl,
          rating: 0,
          createdBy: {
            id: currentUser.uid,
            name: currentUser.displayName
          }
        });
        props.handleClose();
      }
    );
  };

  const handleSave = async (data) => {
    if (data.file) {
      uploadFile(data);
    } else {
      try {
        await shouldUpdateData(data);
        props.handleClose();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <NutritionPlanModal
      isLoading={isLoading}
      modalTitle={
        props.defaultValues ? t('edit nutrition plan') : t('addNutrition')
      }
      {...props}
      handleSave={handleSave}
      onChange={handleFieldChange}
    />
  );
};

export default NutritionModal;
