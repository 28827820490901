import React from 'react';

const UsersIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width="100"
      height="100"
      {...props}
    >
      <circle
        cx="50"
        cy="50"
        r="48"
        stroke="black"
        strokeWidth="2"
        fill="white"
      />
      <path
        d="M30,40 C35,20 65,20 70,40 C75,60 50,70 50,80 C50,70 25,60 30,40"
        fill="lightgray"
      />
      <path d="M30,40 C25,60 50,70 50,80 C50,70 75,60 70,40" fill="gray" />

      <rect x="35" y="55" width="10" height="10" fill="black" />
      <rect x="55" y="55" width="10" height="10" fill="black" />
      <line x1="35" y1="60" x2="55" y2="60" stroke="black" strokeWidth="4" />
    </svg>
  );
};

export default UsersIcon;
