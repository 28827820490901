import { Grid } from '@material-ui/core/';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, updateUser } from '../../features/users/usersSlice';
import Modal from '../Modal';
import TextFieldSection from '../TextFieldSection';

const CreateUserModal = ({ isOpen, handleClose, defaultValues }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(null);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const { isLoading } = users;
  const isEditing = defaultValues;

  const {
    control,
    watch,
    trigger,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || {
      firstName: '',
      lastName: '',
      phone: ''
    }
  });

  useEffect(() => {
    trigger();
    const subscription = watch((value) => {
      setFormData({ ...value });
    });

    return () => subscription.unsubscribe();
  }, [watch, isValid, errors, defaultValues, trigger]);

  const handleSavePress = async () => {
    try {
      if (isEditing) {
        const { firstName, lastName, phone } = formData;

        dispatch(
          updateUser({
            name: {
              first: firstName,
              last: lastName,
              full: `${firstName} ${lastName}`
            },
            phone: '+' + phone,
            id: defaultValues.id
          })
        );
      } else {
        await dispatch(createUser(formData)).unwrap();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSave={handleSavePress}
      title={isEditing ? t('edit user') : t('create user')}
      isLoading={isLoading}
      disabled={!isValid}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextFieldSection
            title={t('name.firstName')}
            placeholder={t('name.firstNamePlaceholder')}
            isValid={!errors.firstName}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error.required')
              }
            }}
            name="firstName"
            defaultValue={defaultValues?.firstName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldSection
            title={t('name.lastName')}
            placeholder={t('name.lastNamePlaceholder')}
            isValid={!errors.lastName}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error.required')
              }
            }}
            name="lastName"
            defaultValue={defaultValues?.lastName}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextFieldSection
            title={t('email')}
            placeholder={t('emailPlaceholder')}
            isValid={!errors.email}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error.required')
              },
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: t('error.invalidEmail')
              }
            }}
            name="email"
            defaultValue={defaultValues?.email}
          />
        </Grid> */}
        <Grid item xs={12}>
          <TextFieldSection
            placeholder={t('name.phonePlaceholder')}
            isValid={!errors.phone}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('error.required')
              }
            }}
            name="phone"
            defaultValue={defaultValues?.phone}
            title={t('phone')}
            phoneInput
            textField={false}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

CreateUserModal.propTypes = {
  introText: PropTypes.string,
  isOpen: PropTypes.bool,
  isEditing: PropTypes.bool,
  children: PropTypes.instanceOf(Element),
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  onSave: PropTypes.func,
  title: PropTypes.string,
  addUser: PropTypes.func,
  state: PropTypes.object
};

export default CreateUserModal;
