import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { string } from 'prop-types';
import React, { useEffect, useRef } from 'react';

const Description = ({ text }) => {
  const textRef = useRef();

  const isOverflowActive = (event) => {
    return (
      event.offsetHeight < event.scrollHeight ||
      event.offsetWidth < event.scrollWidth
    );
  };

  useEffect(() => {
    if (isOverflowActive(textRef.current)) {
      // setOverflowActive(true);
      return;
    }

    // setOverflowActive(false);
  }, [isOverflowActive]);

  return (
    <>
      <Box mt={1.5} ref={textRef}>
        <Typography
          variant="body1"
          style={{
            lineHeight: '20px'
          }}
        >
          {/* {!overflowActive ? text : text.slice(0, 200)} */}
          {text}
        </Typography>
      </Box>
      {/* {!textOpen && !overflowActive ? null : (
        <span
          onClick={() => setTextOpen(!textOpen)}
          style={{ cursor: 'pointer', color: '#00c696' }}
        >
          {!textOpen ? t('read_more') : textOpen && t('read_less')}
        </span>
      )} */}
    </>
  );
};

Description.propTypes = {
  text: string
};

export default Description;
